<template>
  <CargarMapa
    :configMapa="configMapa"
    apiKey="AIzaSyBnYcZVpTfNvd1NZbvWrmvijqx9IGo3I_8"
  >
 
    <template slot-scope="{ google, map }">
      <Marcadores
        v-for="marker in markers"
        :key="marker.id"
        :marker="marker"
        :google="google"
        :map="map"
      />
      
    </template>
 
  </CargarMapa>
 
</template>

<script>
 
  import CargarMapa from "@/components/GoogleMapLoader";
  import Marcadores from "@/components/Marcadores";
  import { configMapa } from "@/constans/configMapa";
 
  export default {
      props:{
          posiciones:Object
      },
    components: {
      CargarMapa,
      Marcadores
    },
 
    data() {
      return {
        markers: this.posiciones
      };
    },
 
    computed: {
      configMapa() {
        return {
          ...configMapa,
          center: this.mapCenter
        };
      },
 
      mapCenter() {
        return this.markers[0].position;
      }
    }
  };
  
</script>