<template>
  <div class="main-content home">
    <div class="slider-section">
    <vueper-slides :bullets="false" :autoplay="true" :fixed-height="setSliderHeight()" >
        <vueper-slide v-for="slide in slides" :key="'A'+slide.id">
           
            <template #content>
            <div class="item-slide">
        <div
          class="carr-inicio"
          v-bind:style="{ backgroundImage: 'url( '+urlPortada+encodeURI(slide.portada)+')' }"
        >
          <div class="carousel-caption">
            <div class="slider-content">
              <h4>${{formatoNumero(slide.precio)}} {{slide.moneda}}</h4>
              <h3>{{ slide.titulo}}</h3>
              <p></p>
              <p>
               {{ slide.descripcion.substr(0,80) }}...
              </p>
              <p></p>
              <a
                href="propiedad/oficina-en-venta-col.-polanco"
                title="caption-arrow"
                class="caption-arrow"
              >
                <ion-icon name="arrow-forward"></ion-icon>
              </a>
            </div>
          </div>
        </div>
            </div>
            </template>
        </vueper-slide>
    </vueper-slides>
    </div>


     <div id="search-section" class="search-section">
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-sm-9 p_l_z">
            <select v-model="tipo" id="tipo" style="text-transform:uppercase">
              <option value>Tipo</option>
               <option style="text-transform:uppercase" v-for="res in restipo" :key="'B'+res.id" v-bind:value="res.nombre">{{res.nombre}}</option>
            </select>
            <select v-model="ubicacion" id="ubicacion" @change="llenarColonias" style="text-transform:uppercase">
              <option value>Ubicación</option>
              <option style="text-transform:uppercase" v-for="res in resubi" :key="'C'+res.estado" v-bind:value="res.estado">
                {{res.estado.toUpperCase()}}
              </option>
            </select>
            <select v-model="colonia" id="colonia"  style="text-transform:uppercase">
              <option value>COLONIA</option>
              <option  style="text-transform:uppercase" v-for="col in rescolonia" v-bind:value="col.colonia" :key="'D'+col.colonia" >
                  {{col.colonia.toUpperCase()}}
              </option> 
              
            </select>
            <select v-model="op" id="operacion" style="text-transform:uppercase">
              <option value>Operacion</option>
              <option value="Renta">Renta</option>
              <option value="Venta">Venta</option>
              <option value="Preventa">Preventa</option>
            </select>
            <select v-model="recamaras" id="recamaras" style="text-transform:uppercase">
              <option value>Recamaras</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
            </select>

            <select v-model="estacionamiento" id="estacionamiento" style="text-transform:uppercase">
              <option value>Estacionamiento</option>
              <option value="1">1 Lugar</option>
              <option value="2">2 Lugares</option>
              <option value="3">3 Lugares</option>
              <option value="4">4 Lugares</option>
              <option value="5">5 Lugares</option>
            </select>

            <select v-model="banos" id="banos" style="text-transform:uppercase">
              <option value>Baños</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
            <select v-model="medbanos" id="mbanos" style="text-transform:uppercase">
              <option value>1/2 Baños</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
            <input
              type="number"
              list="minprecios"
              v-model="minprecio"
              id="minprecio"
              style="text-transform:uppercase"
              placeholder="Precio Minimo"
            />

            <input
              type="number"
              list="maxprecios"
              v-model="maxprecio"
              id="maxprecio"
              style="text-transform:uppercase"
              placeholder="Precio Máximo"
            />
          </div>

          <div class="col-md-2 col-sm-3">
            <div class="section-header">
              <h3><span>Buscar</span>Propiedades</h3>
              <button
                title="search"
                class="btn-principal"
                @click="urlAction"
              >
                Buscar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="rent-and-sale-section" class="rent-and-sale-section">
      <div class="container">
        <div class="rent-property row">
          <div class="col-md-12">
            <div class="section-header mb-4 text-center">
              <h3><span>Propiedades</span>En Preventa</h3>
              <p>Nuestra lista de propiedades más destacadas</p>
            </div>
          </div>
          <div class="col-md-12 p_r_z">
            <div id="rent-property-block" class="rent-property-block propiedades-inicio">
              <div class="container">

              <vueper-slides :bullets="false" :arrows-outside="true" :fixed-height="propsini()"  :breakpoints="breakpoints" :visible-slides="3">
                <vueper-slide v-for="preventa in preventas" :key="'E'+preventa.id">
                    <template #content>
                  <div class="carousel__item">
                    <div class=" rent-block">
                      <div class="property-main-box" style="height: auto">
                        <div class="property-images-box">
                          <router-link
                            :to="{
                              name: 'Detalle',
                              params: { url: preventa.url },
                            }"
                          >
                            
                              <img
                                :src="
                                  `https://fincabienesraices.com.mx/Admin/imagenes/` +
                                  preventa.id +
                                  `/thumb-portada/` +
                                  preventa.img_perfil
                                "
                                alt="Image"
                                data-class="img-fluid"
                                style="height: 170px"
                              />
                            
                          </router-link>
                          <h4 style="font-size: 15px">
                            ${{ formatoNumero(preventa.precio) }}
                            {{ preventa.moneda }}
                          </h4>
                        </div>
                        <div class="clearfix"></div>
                        <div class="property-details">
                          <router-link
                            :to="{
                              name: 'Detalle',
                              params: { url: preventa.url },
                            }"
                          >
                          {{ preventa.titulo }}
                          </router-link>
                           <p class="mb-1" style="font-size:11px;color:rgb(100 96 96);"><ion-icon name="location-outline"></ion-icon> {{preventa.ciudad}}, {{preventa.estado}} </p>
                          <ul>
                           <li><ion-icon style="font-size:20px" name="expand-outline"></ion-icon> 
                      {{preventa.mtrs_construccion}} M<sup>2</sup></li>
                            <li v-if="preventa.recamaras != '-1'">
                              <i
                                ><img
                                  :src="require('@/assets/images/bed-icon.png')"
                                  alt="bed-icon"
                              /></i> {{preventa.recamaras}}
                            </li>
                            <li v-if="preventa.banos_completos != '-1'">
                              <i
                                ><img
                                  :src="
                                    require('@/assets/images/bath-icon.png')
                                  "
                                  alt="bath-icon"
                              /></i> {{preventa.banos_completos}}
                            </li>
                          </ul>
                        </div>
                         <router-link
                         class="footer"
                            :to="{
                              name: 'Detalle',
                              params: { url: preventa.url },
                            }"
                          >
                        <div class="property-footer">Ver Propiedad</div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                  </template>
                </vueper-slide>
              </vueper-slides>
              </div>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="sale-property row">
          <div class="col-md-12">
            <div class="section-header text-center mb-4">
              <h3><span>Propiedades</span>En Venta</h3>
              <p>Nuestra lista de propiedades más destacadas</p>
            </div>
          </div>
          <div class="col-md-12 p_r_z">
            <div id="sale-property-block" class="sale-property-block propiedades-inicio">
              <div class="container">
              <vueper-slides :bullets="false" :arrows-outside="true"  :visible-slides="3"  :fixed-height="propsini()" :breakpoints="breakpoints">
                <vueper-slide v-for="preventa in ventas" :key="'F'+preventa.id">
                    <template #content>
                        <div class="carousel__item">
                            <div class="col-md-12 rent-block">
                            <div class="property-main-box" style="height: auto">
                                <div class="property-images-box">
                                <div
                                >
                                
                                    <img
                                        :src="
                                        `https://fincabienesraices.com.mx/Admin/imagenes/` +
                                        preventa.id +
                                        `/thumb-portada/` +
                                        preventa.img_perfil
                                        "
                                        alt="Image"
                                        data-class="img-fluid"
                                        style="height: 170px"
                                    />
                                
                                </div>
                                <h4 style="font-size: 15px">
                                   
                                    ${{ formatoNumero(preventa.precio) }}
                                    {{ preventa.moneda }}

                                   
                                </h4>
                                </div>
                                <div class="clearfix"></div>
                                <div class="property-details">
                                 <router-link
                            :to="{
                              name: 'Detalle',
                              params: { url: preventa.url },
                            }"
                          >
                                   {{ preventa.titulo }}</router-link
                                >
                                <p class="mb-1" style="font-size:11px;color:rgb(100 96 96);"><ion-icon name="location-outline"></ion-icon> {{preventa.ciudad}}, {{preventa.estado}}</p>
                                <ul>
                                    <li v-if="preventa.mtrs_construccion != '-1'"><ion-icon style="font-size:20px" name="expand-outline"></ion-icon> 
                      {{preventa.mtrs_construccion}} M<sup>2</sup></li>
                                    <li v-if="preventa.recamaras != '-1'">
                                      <i
                                          ><img
                                          :src="require('@/assets/images/bed-icon.png')"
                                          alt="bed-icon"
                                      /></i> {{preventa.recamaras}}
                                    </li>
                                    <li v-if="preventa.banos_completos != '-1'">
                                      <i
                                          ><img
                                          :src="
                                              require('@/assets/images/bath-icon.png')
                                          "
                                          alt="bath-icon"
                                      /></i> {{preventa.banos_completos}}
                                    </li>
                                </ul>
                                </div>
                                 <router-link
                                 class="footer"
                            :to="{
                              name: 'Detalle',
                              params: { url: preventa.url },
                            }"
                          >
                                <div class="property-footer">Ver Propiedad</div>
                                 </router-link>
                            </div>
                            </div>
                        </div>
                  </template>
                </vueper-slide>
              </vueper-slides>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div id="featured-section" class="featured-section container-fluid p_z">
      <div class="container">
        <div class="section-header">
          <p>Trending</p>
          <h3>Inmuebles Destacados</h3>
        </div>
        <div id="featured-property" class="featured-property row">
          <div class="container">
<vueper-slides :arrows-outside="true" :fixed-height="destaH()">
            <vueper-slide v-for="prop in destacadas" :key="'G'+prop.id" >
                 <template #content>
              <div class="item row">
                <div class="col-md-6 rent-block">
                  <div class="property-main-box" style="height: auto">
                    <div class="property-image-dest property-images-box">
                      <span>
                        <ion-icon name="star"></ion-icon>
                        <ion-icon name="star"></ion-icon>
                        <ion-icon name="star"></ion-icon>
                        <ion-icon name="star"></ion-icon>
                        <ion-icon name="star"></ion-icon>
                      </span>
                      <a
                        title="<?=$row['titulo']?>"
                        href="propiedad/<?=$row['url']?>"
                      >
                        <img
                          style="height: 351px"
                          :src="
                            `https://fincabienesraices.com.mx/Admin/imagenes/` +
                            prop.id +
                            `/thumb-portada/` +
                            prop.img_perfil
                          "
                          alt="featured"
                        />
                      </a>
                      <h4>${{formatoNumero(prop.precio)}} {{prop.moneda}}</h4>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                  <!-- Property Main Box -->
                </div>
                <div class="col-md-6">
                  <div class="property-details">
                     <router-link
                            :to="{
                              name: 'Detalle',
                              params: { url: prop.url },
                            }"
                          >
                      {{ prop.titulo }}
                     </router-link>
                      <p class="mb-1" style="font-size:12px;color:rgb(100 96 96);"><ion-icon name="location-outline"></ion-icon> {{prop.ciudad}}, {{prop.estado}}</p>
                      {{ prop.descripcion.substr(0,180) }}...

                    <hr />
                    <ul>
                      <li><ion-icon style="font-size:20px" name="expand-outline"></ion-icon> 
                      {{prop.mtrs_construccion}} M<sup>2</sup></li>

                      <li v-if="prop.recamaras != '-1'">
                        <i
                          ><img
                            :src="require('@/assets/images/bed-icon.png')"
                            alt="bed-icon"
                        /></i> {{prop.recamaras}}
                      </li>

                      <li v-if="prop.banos_completos != '-1'">
                        <i
                          ><img
                            :src="require('@/assets/images/bath-icon.png')"
                            alt="bath-icon"
                        /></i> {{prop.banos_completos}}
                      </li>
                    </ul>
                    <hr />
                    <div class="row">
                      <div class="col-md-5">
                        <p class="h5">
                          <span style="font-size: 13px"
                            >¿Quieres conocer más propiedades?</span
                          >
                          <br /><br />
                          Suscríbete a nuestro FincaNews
                        </p>
                      </div>
                      <div class="col-md-7">
                        <div class="destacado-feedback-form">
                          <input
                            type="text"
                            id="destacada-news<?=$row['id']?>"
                            name="contact-name "
                            placeholder="Correo Electrónico"
                            required=""
                          />
                          <router-link
                            :to="{
                              name: 'Detalle',
                              params: { url: prop.url },
                            }"
                          >
                          <button
                            data-toggle="modal"
                            data-target="#newsletter-modal"
                            onClick="newsletter('destacada-news<?=$row['id']?>')"
                            class="mt-2 btn-principal"
                          >
                            Suscribirme
                          </button>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
               </template>
            </vueper-slide>
             
          </vueper-slides>
          </div>
          
        </div>
      </div>
    </div>
  </div>    
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import axios from "axios";
 
export default {
    
    data(){
        return{
            tipo:"",
            restipo:[],
            ubicacion:"",
            resubi:[],
            colonia:"",
            rescolonia:[],
            op:"",
            recamaras:"",
            estacionamiento:"",
            banos:"",
            medbanos:"",
            minprecio:"",
            maxprecio:"",
            preventas: [],
            ventas: [],
            destacadas: [],
            slides:[],
            urlPortada:"https://fincabienesraices.com.mx/Admin/imagenes/portadas/thumbs/",
            breakpoints:{
              1200: {
                visibleSlides:3
              },
              900: {
                visibleSlides: 2
              },
              600: {
                visibleSlides: 1
              }
            },
        }
    },
    components: { VueperSlides, VueperSlide },
    methods:{
       urlAction() { 
      
      this.$router.push({ path: "/buscar", query: { tipo: this.tipo , ubicacion: this.ubicacion,colonia : this.colonia,operacion: this.operacion,
        recamaras: this.recamaras, estacionamiento : this.estacionamiento, banos:this.banos, op:this.op, medbanos:this.medbanos,
        minprecio:this.minprecio, maxprecio:this.maxprecio
      } });
    },
      async llenarbarrabuscadora(){
        await axios.get('https://fincabienesraices.mx/api/resBarra')
        .then((response)=>{
          this.restipo = response.data.tipos;
          this.resubi = response.data.ubicaciones;
          this.rescolonia = response.data.colonias;
        })
        .catch((error)=>{
          console.log('Error al conectar API restipos=>',error);
        });
      },
      async llenarColonias(){
         await fetch('https://fincabienesraices.com.mx/api/buscar-colonias', {
            method: 'POST',
            body: JSON.stringify({
              ciudad: this.ubicacion, 
            })
          }).then(response => response.json())
            .then(data =>{
              console.log(data);
              if(data.code == 200){
                this.rescolonia = [];
                this.rescolonia = data.res;
              }
            });
      },
      setSliderHeight(){
        var newHeight = 0;
        if(screen.width < 767){
           newHeight = '190px'
        }else{
           newHeight = '350px'
        }
         // calculate a height here later
      return newHeight
      },
      destaH(){
        var newHeight = 0;
        if(screen.width < 767){
         newHeight = '1106px'
        }else{
          newHeight = '400px'
        }
        
        return newHeight;
      },
      propsini(){
        const newHeight = '400px'
        return newHeight;
      },
        formatoNumero(numero) {
        var n = numero;
        n = String(n).replace(/(.)(?=(\d{3})+$)/g, "$1,");

        return n;
        },
        async getDatos(){
          await axios
      .get("https://fincabienesraices.com.mx/api/propiedad/carrusel")
      .then((response) => {
        if (response.data.error) {
          console.log("Error al consultar las propiedades en carrusel ");
        } else {
          console.log(response.data);
          this.slides = response.data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
         await  axios
      .get("https://fincabienesraices.mx/api/propiedad/preventa")
      .then((response) => {
        if (response.data.error) {
          console.log("Error al consultar las propiedades en preventa");
        } else {
          console.log(response.data);
          this.preventas = response.data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
     await axios
      .get("https://fincabienesraices.mx/api/propiedad/venta")
      .then((response) => {
        if (response.data.error) {
          console.log("Error al consultar las propiedades en venta ");
        } else {
          console.log(response.data);
          this.ventas = response.data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
   await axios
      .get("https://fincabienesraices.com.mx/api/propiedad/destacada")
      .then((response) => {
        if (response.data.error) {
          console.log("Error al consultar las propiedades en venta ");
        } else {
          console.log("destacadas => ",response.data);
          this.destacadas = response.data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
     
        }
    },
      mounted() {
        document.title = "Finca Bienes Raices - Tu casa, depa, negocio a un clic";
        this.llenarbarrabuscadora();
        this.getDatos();
     
  },
    
}
</script>

<style scoped>

.property-images-box img{
  object-fit: cover;
}
.vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:after, .vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:before{
  box-shadow: none;
}
.carousel__item{
  margin: 0 10px;
}
.property-main-box a.footer{
  text-decoration: none;
}
@media only screen and (min-width: 319px) and (max-width: 767px){
  .row{
    margin-left: unset !important;
    margin-right: unset !important;;
  }
  .search-section .col-md-10 input{
    width: 100%;
    margin-left: 0;
  }
}
</style>