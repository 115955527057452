<template>
<div class="page-content">
    <div class="container">
        <div class="row justify-content-center text-center">
            <div class="col-md-5" v-if="!isSession">
                <h1>Iniciar Sesión</h1>
                    <div class="formulario-login">
                        <input type="text" v-model="usuario" placeholder="Ingrese su usuario">
                        <input type="password" v-model="pass" placeholder="Ingrese su contraseña">
                        <button @click="login" class="button-finca">Iniciar Sesión</button>
                    </div>

            </div>
            <div class="col-md-5" v-if="isSession">
                <img src="https://fincabienesraices.com.mx/images/logo.png" alt="" srcset="" style="width:100px">
                <h1 class="py-4">Hola {{nombreBroker}}</h1>
                <h4>Ahora las fichas tecnicas saldran con tus datos personales.</h4>
                <p>Saludos</p>
                <button @click="cerrarSesion" class="button-finca">Cerrar Sesión</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
//import axios from 'axios'
export default {
    data(){
        return{
            usuario:"",
            pass:"",
            nombreBroker:"",
            isSession:false,
            token:localStorage.getItem("tokenSession_fi") || null
        }
    },
    methods:{
        revisarSesion(){
             
            if(localStorage.getItem("tokenSession_fi")){
                this.isSession = true;
                this.nombreBroker = localStorage.getItem("nombreBroker_fi");
            }
        },
        cerrarSesion(){
            localStorage.removeItem('tokenSession_fi');
            localStorage.removeItem('nombreBroker_fi');
            localStorage.removeItem('idb_fi');
            this.isSession = false;
        },
        async login() {   
            await fetch("https://fincabienesraices.com.mx/api/login", {  
                method: "POST",
                body: JSON.stringify({
                usuario: this.usuario,
                pass: this.pass
                })
            }).then(response => response.json())
                .then(data =>{
                   if(data.code == 200){
                        this.nombreBroker = data.res.nombre;
                        this.isSession = true;
                        const token = data.res.token;
                        this.token = token
                        localStorage.setItem('tokenSession_fi',data.res.token);
                        localStorage.setItem('nombreBroker_fi',data.res.nombre);
                        localStorage.setItem('idb_fi',data.res.idBroker);
                    }else{
                        if(data.code == 500){
                            alert("Tus datos son incorrectos, intenta nuevamente");
                        }
                    }  
                });
            //console.log(res.code);
            /**/
            
        },
    },
    mounted(){
        
        this.revisarSesion();

    }
}
</script>

<style>
.formulario-login{
    margin: 2rem 0;
    display: flex;
    flex-direction: column;
}
.formulario-login input{
    padding: 10px 20px;
    border-radius: 15px;
    border: 0.2px solid #000;
    margin-bottom: 1rem;
    letter-spacing: 1.2px;
    width: 100%;
}
.button-finca{
    background-color: #ea0c0c;
    padding: 10px 0px;
    border: none;
    width: 100%;
    color: #fff;
    font-weight: 600;
    letter-spacing: 1.2px;
}
</style>