<template>
  <div>
    <Mapa
      :posiciones="makrs"></Mapa>
  </div>
</template>

<script>
import Mapa from '@/components/Mapa.vue';
let markerts = [
          {
            id: "0",
            position: { lat: -13.1583635, lng: -72.603623 },
            title: "Machu Pichu - Cusco"
          },
          {
            id: "1",
            position: { lat: -12.025827, lng: -77.2679817 },
            title: "Lima - Perú"
          }
        ];
export default {
  components:{
    Mapa
  },
  data() {
    return {
      makrs:markerts
    }
  }
}
</script>

<style>

</style>