<template>
<div>
  <div id="footer-section" class="footer-section">
    <div class=" container">
      <div class="row">
          <div class="col-md-4 col-sm-6">
        <aside class="widget widget_about">
          <h3 class="widget-title text-white">Nosotros</h3>
          <p class="text-white">
            Somos la mejor opción para la compra, venta y renta de inmuebles en
            la Ciudad de México. Nos respaldan 25 años de experiencia y miles de
            clientes satisfechos en las mejores zonas de la CDMX: Polanco,
            Condesa, Santa Fe, Roma, Pedregal, San Angel y San Jerónimo.
          </p>
        </aside>
      </div>

      <div class="col-md-4 col-sm-6">
        <aside class="widget widget_newsletter">
          <h3 class="widget-title text-white">NewsLetter</h3>
          <div class="input-group">
            <input
              type="text"
              id="pie-news"
              class="form-control"
              placeholder="Ingresa tu correo"
              v-model="cEmail"
            />
            <span class="input-group-btn">
              <button
                @click="showModal = true"
                class="btn btn-default"
                type="button"
                data-toggle="modal"
                data-target="#newsletter-modal"
                onClick="newsletter('pie-news')"
              >
                Ir
              </button>
            </span>
          </div>
          <p class="text-white">
            Recibe notificaciones de las nuevas propiedades que tenemos
          </p>
        </aside>
      </div>

      <div class="col-md-4 col-sm-6">
        <aside class="widget widget_newsletter">
          <h3 class="widget-title text-white">Propiedades Recientes</h3>
          <div class="foot-relast">
						<div class="item-re">
							<img style="width: 262px;height: 110px" :src="imagen" alt="" >
						</div>
						<div class="item-re desc">
							<router-link class="text-white" :to="{
                              name: 'Detalle',
                              params: { url: url },
                            }"><p style="color:white">{{titulo}}</p></router-link>
							<p class="text-white">{{precio}}</p>
						</div>
					</div>
        </aside>
      </div>
      </div>
      
    </div>

    <div class="foot-back"></div>

    <div id="footer-bottom" class="footer-bottom">
      <div class="container">
        <p class="col-md-4 col-sm-6 col-xs-12">
          &copy; 2020 Finca Inmobiliaria ‐ Todos los derechos reservados
        </p>
        <div class="col-md-4 col-sm-6 col-xs-12 pull-right social">
          <ul class="footer_social m_b_z">
            
          </ul>
          <a href="#" title="back-to-top" id="back-to-top" class="back-to-top">
            <i class="fa fa-long-arrow-up"></i> Top</a>
        </div>
      </div>
    </div>
  </div>

  <a
    :href="`https://api.whatsapp.com/send?phone=+525584932033&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20la%20propiedad%20`+ urlw"
    class="float d-flex justify-content-center align-items-center"
    target="_blank"
  >
    <ion-icon name="logo-whatsapp"></ion-icon>
  </a>

<!-- Modal -->
 <div v-if="showModal">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="contact-feedback-form">
        <div class="modal-header">
          
          <h4 class="modal-title">Suscribirse a nuestro newsletter</h4>
          <button type="button" @click="showModal = false"  class="cerrar" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
          <p align="center">Recibe todas las noticias y promociones de Finca Bienes Raices </p>
			
						
						<form>
							<div class="col-md-12 col-xs-12">
								<input type="text" id="input_name_newsletter" v-model="cNombre" name="contact-name" placeholder="Escribe tu nombre" required>
							</div>
							<div class="col-md-12 col-xs-12">
								<input type="email" id="input_email_newsletter" name="contact-email" v-model="cEmail" placeholder="Escribe tu correo electronico" required>
							</div>
							<div class="col-md-12 col-xs-12">
								<a  class="btn-principal"  @click="enviarD()">Suscribirme</a>
							</div>
							<div class="col-md-12 col-sm-12">
								<div id="alert-msg" class="alert-msg"></div>
							</div>
						</form>
					
        </div>
      </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>

  </div>
</template>

<script>
import axios from 'axios'
export default {
  data(){
    return{
      titulo:"",
      imagen:"",
      url:"",
      urlw:window.location.href,
      precio:"",
      showModal: false,
      cNombre:"",
      cEmail:"",
      cTelefono:"",
      cMensaje:"",
      urlfinal:window.location.href,
      isEnviando:false
    }
  },
  methods:{
    async enviarD(){
      this.isEnviando = true;
      await fetch('https://fincabienesraices.com.mx/api/datosNews', {
        method: 'POST',
        body: JSON.stringify({
          nombre: this.cNombre, 
          correo: this.cEmail,
          telefono:"",
          propiedad:"Suscripción",
          mensaje:"Este usuario se suscribio para recibir email",
          url:window.location.href
        })
      }).then(response => response.json())
        .then(data =>{
          console.log(data); 
          if(data.code == 200){
            alert("Tus datos fueron enviados, en un momento más nos comunicaremos contigo.");
            this.isEnviando = false;
          }else{
            alert("Error al conectar, por favor intenta nuevamente.");
            this.isEnviando = false;
          }
        });
      },
    formatoNumero(numero) {
        var n = numero;
        n = String(n).replace(/(.)(?=(\d{3})+$)/g, "$1,");

        return n;
        },
    async getUltima(){
      await axios.get("https://fincabienesraices.com.mx/api/propiedad/ultima")
      .then(res=>{
        this.titulo = res.data.titulo;
        this.imagen = "https://fincabienesraices.com.mx/Admin/imagenes/"+res.data.id+"/thumb-portada/"+res.data.img_perfil;
        this.url = res.data.url;
        this.precio = "$"+ this.formatoNumero(res.data.precio) + res.data.moneda;
      })
      .catch(error=>{
        console.log(error);
      });
    }
  },
  mounted(){
    this.getUltima();
  }
}
</script>
<style  >
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal .cerrar{
  border: none;
    background: none;
    font-size: 24px;
}
</style>
