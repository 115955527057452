<template>
<div>
 
  <div class="page-content" id="propiedadpdf">
    <div class="property-main-details">
      <div v-if="cargando">
        <div v-if="errorcargar">
          <h3>No se cargo nada</h3>
        </div>
      </div>
      <div v-if="!errorcargar" class="container">
        <div class="property-header">
          <h3>
            {{ titulo }} <span>En {{ operacion }}</span>
          </h3>

          <ul>
            <li>${{ formatoNumero(precio) }} {{ moneda }}</li>
            <li>ID : {{id}}</li>
            <li v-if="recamaras != '-1'">
              <img src="~@/assets/images/bed-icon.png" alt="Recamáras disponibles"> {{ recamaras }}
            </li>
            <li v-if="banos_completos != '-1'">
              <img src="~@/assets/images/bath-icon.png" alt="Baños completos disponibles"> {{ banos_completos }} 
            </li>
            <li v-if="banos_medios != '-1'">
              ½ <img src="~@/assets/images/bath-icon.png" alt="Medios baños disponibles">{{banos_medios}}
            </li>
          </ul>
          <div class="ver-mob"><br /><br /><br /></div>
          <a title="Imprimir ficha técnica" class="btn-ficha" target="_blank" :href="`https://fincabienesraices.com.mx/fichatecnica_test/?id=`+id+`&nombre=`+url+broker"
            ><ion-icon name="print-sharp"></ion-icon> Generar Ficha Técnica {{nombreBroker}}</a
          >
        </div>
        <div class="row">
          <div class="col-md-6 col-sm-6 p_z">
            <div class="image-detalle-prop">
              <img
                :src="
                  `https://fincabienesraices.com.mx/Admin/imagenes/` +
                  id +
                  `/thumb-portada/` +
                  img_perfil
                "
                alt="Slide"
                style="width: 100%; margin-bottom: 30px; border-radius: 15px"
              />
            </div>
            <div class="row">
<div
      v-for="(img, index) in imgs"
      :key="index"
      class="pic col-md-4 col-4"
      @click="() => showImg(index)"
    >
      <img style="object-fit:cover; height:80px;width:100%; padding: 5px 5px" :src="typeof img === 'string' ? img : img.src" />
    </div>
            </div>
            
                <vue-easy-lightbox
      :visible="visible"
    :imgs="imgs"
    :index="index"
    @hide="handleHide"
    ></vue-easy-lightbox>
              
              
            <div class="single-property-details">
              <h3>Descripción</h3>
              <p v-html="descripcion"></p>
            </div>
            <div class="general-amenities pull-left">
              <h3>Caracteristicas generales</h3>
              <div class="amenities-list pull-left row">
                <div class="col-md-6 col-sm-6 col-12" v-for="row in caracteristicas" :key="row">
                  <div class="amenities-checkbox">
                    <ion-icon name="checkmark-circle-outline" class="success"></ion-icon>
                    <label for="checkbox-1">{{row}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="
              col-md-6 col-sm-6
              p_z
              property-sidebar
              single-property-sidebar
            "
          >
            <aside class="widget widget-search info-enviar">
              <div class="loading-info" v-if="isEnviando">
                <div class="loader"></div>
                <h3>Enviando datos</h3>
              </div>
              <div class="uno">
                <h2 class="widget-title">
                  ¿Quieres más información?<span>Contáctanos</span>
                </h2>
              
                  <input
                    type="text"
                    placeholder="Nombre Completo"
                    v-model="cNombre"
                    required
                  />
                  <input
                    type="text"
                    placeholder="Correo"
                    v-model="cEmail"
                    required
                  />
                  <input type="text" placeholder="Teléfono" v-model="cTelefono" required />
                  <textarea placeholder="Mensaje" v-model="cMensaje" required></textarea>
                  
                  <div class="row">
                    <div class="col-md-6">
                      <input
                        type="submit"
                        value="Enviar información"
                        class="btn"
                        @click="enviarDatos"
                      />
                    </div>
                    <div class="col-md-6">
                      <a
                      target="_blank"
                        :href="`https://api.whatsapp.com/send?phone=+525584932033&text=Hola%21%20quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20la%20propiedad%20*`+urlfinal+`*`"
                        ><button type="button" class="wsp-prop">
                          Enviar Whatsapp
                        </button></a
                      >
                    </div>
                   </div>
                </div>
              
            </aside>
            <a :href="`whatsapp://send?text=`+urlfinal">
              <button class="wsp-prop" title="print">
              <i class="fa fa-whatsapp"></i> Compartir Ficha por Whatsapp
            </button>
              </a> 
            <div class="property-direction pull-left">
              <div class="property-map">
                <h3>Compartir esta propiedad :</h3>
                <ul>
                  <li>
                    <a
                      href="javascript: void(0);"
                      onClick="window.open('https://twitter.com/intent/tweet?text='+window.location.href,'ventanacompartir', 'toolbar=0, status=0, width=650, height=450');"
                      title="twitter"
                      >
                      <ion-icon name="logo-twitter"></ion-icon>
                      </a>
                  </li>
                  <li>
                    <a
                      
                      onClick="window.open('http://www.facebook.com/sharer.php?u='+window.location.href,'ventanacompartir', 'toolbar=0, status=0, width=650, height=450');"
                      title="facebook"
                      ><ion-icon name="logo-facebook"></ion-icon></a>
                  </li>
                  <li>
                    <a :href="`whatsapp://send?text=`+urlfinal"
                      ><ion-icon name="logo-whatsapp"></ion-icon> </a>
                  </li>
                </ul>
              </div>
              <div class="property-map">
                   <Mapa
                 v-if="onMapa"
                   class="mapa"
                :posiciones="coordenadas"></Mapa>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
   </div>
</template>

<script>

import axios from "axios";
import VueEasyLightbox from 'vue-easy-lightbox'
import Mapa from '@/components/Mapa.vue'
let coor = [
  {
            id: "0",
            position: { lat: -13.1583635, lng: -72.603623 },
            title: "Machu Pichu - Cusco"
          },
];
export default {
  
  components: {
    VueEasyLightbox,
    Mapa
  },
  methods: {
    async enviarDatos(){
      this.isEnviando = true;
      await fetch('https://fincabienesraices.com.mx/api/datosNews', {
      method: 'POST',
      body: JSON.stringify({
        nombre: this.cNombre, 
        correo: this.cEmail,
        telefono:this.cTelefono,
        propiedad:this.url,
        mensaje:this.cMensaje,
        url:window.location.href
      })
    }).then(response => response.json())
      .then(data =>{
         console.log(data); 
         if(data.code == 200){
           alert("Tus datos fueron enviados, en un momento más nos comunicaremos contigo.");
           this.isEnviando = false;
         }else{
           alert("Error al conectar, por favor intenta nuevamente.");
           this.isEnviando = false;
         }
      });
    },
    revisarSesion(){     
            if(localStorage.getItem("tokenSession_fi")){
              this.idb = localStorage.getItem('idb_fi')
              this.nombreBroker = localStorage.getItem('nombreBroker_fi');
              this.broker = "&broker="+this.idb;
            }
        },
    formatoNumero(numero) {
      var n = numero;
      n = String(n).replace(/(.)(?=(\d{3})+$)/g, "$1,");

      return n;
    },
     showImg(index) {
        this.index = index
        this.visible = true
      },
      handleHide() {
        this.visible = false
      }
  },
  data() {
    return {
      isEnviando:false,
      id: "",
      idb:"",
      nombreBroker:"",
      fecha_creacion: "",
      titulo: "",
      descripcion: "",
      precio: "",
      moneda: "",
      onMapa:false,
      operacion: "",
      tipo: "",
      piso: "",
      pisos_totales: "",
      departamentos_totales: "",
      recamaras: "",
      vestidores: "",
      banos_completos: "",
      banos_medios: "",
      estacionamientos: "",
      mtrs_construccion: "",
      mtrs_terreno: "",
      antiguedad: "",
      img_perfil: "",
      caracteristicas: [],
      estado: "",
      ciudad: "",
      colonia: "",
      calle: "",
      num_ext: "",
      num_int: "",
      coordenadas: coor,
      url: "",
      whatsapp: "",
      cargando: true,
      errorcargar: false,
      carruselSlides: [],
      index:0,
      imgs: [],  // Img Url , string or Array of string
      visible: false,
      broker:"",
      cNombre:"",
      cEmail:"",
      cTelefono:"",
      cMensaje:"",
      urlfinal:window.location.href
    };
  },
 
  mounted() {
    this.revisarSesion();
    let urlProp = this.$route.params.url;
    console.log("Hola Parametro ", this.$route.params.url);
    axios
      .get("https://fincabienesraices.mx/api/propiedad/?url="+urlProp)
      .then((response) => {
        if (response.data.error) {
          this.errorcargar = true;
        } else {
          const propiedad = response.data;
          this.id = propiedad.id;
          this.img_perfil = propiedad.img_perfil;
          this.descripcion = propiedad.descripcion;
          this.recamaras = propiedad.recamaras;
          this.url = propiedad.url;
          this.estacionamientos = propiedad.estacionamientos;
          this.moneda = propiedad.moneda;
          this.titulo = propiedad.titulo;
          this.banos_completos = propiedad.banos_completos;
          this.banos_medios = propiedad.banos_medios;
          document.title = propiedad.titulo + " - Finca Bienes Raices";
          this.operacion = propiedad.operacion;
          this.precio = propiedad.precio;
          const imges = JSON.parse(response.data.imagenes);
          var link = document.createElement('meta');
          link.setAttribute('property', 'og:url');
          link.content = document.location;
          document.getElementsByTagName('head')[0].appendChild(link);
          this.caracteristicas = JSON.parse(propiedad.caracteristicas);
          var newCorde = propiedad.coordenadas.split(',');
          console.log(newCorde);
          this.coordenadas = [
          {
            id: "0",
            position: { lat: 19.4315815, lng: -99.1949198 },
            title: "Machu Pichu - Cusco"
          },
          ];
          this.onMapa=true;
          this.imgs = [];
          imges.map((itemres) => {
            var pushcc = encodeURI(
              "https://fincabienesraices.com.mx/Admin/imagenes/" +
                propiedad.id +
                "/" +
                itemres
            );
            this.imgs.push(pushcc);
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
      
  },
};
</script>

<style scoped>
.image-detalle-prop img{
  height: 400px;
  object-fit: cover;
}
.pic:hover{
    cursor: pointer;
}
.property-header ul li:first-child{
  font-size: 27px;
  font-weight: 900;
  color: #0dbae8;
}
.property-header h3{
  font-size: 25px;
}
.success {
  color: #20c933;
}
.btn-ficha{
  text-decoration: none;
}
.btn-ficha:hover{
  background-color: #990f0f;
  color: white;
}
.mapa{
  height: 400px;
}
.property-direction .property-map{
  padding: 0;
}
.info-enviar{
  position: relative;
}
.info-enviar .loading-info h3{
  color: #fff;
  margin-bottom: 15px;
}
.amenities {
  display: flex;
  flex-wrap: wrap;
}
.wrapper > div {
  border: 1px solid red;
  flex: 0 1 50%;
  height: 100px;
}
.wrapper > div:nth-last-child(2):nth-child(even) {
  height: 200px;
}
.wrapper > div:nth-last-child(2):nth-child(even) + div {
  margin-top: -100px;
}
input[type=button] {
  position: fixed;
  top: 20px;
  left: 20px;
}
.wrapper > div:nth-last-child(2):first-child {
  height: 200px;
}
.wrapper > div:nth-last-child(2):first-child + div {
  height: 200px;
}
.info-enviar .loading-info{
  position: absolute;
  top: 0;
  left: 0;
  background: #00000073;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.info-enviar .loading-info .loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #ea0c0c; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
