<template>
  <div>
<Header />
  <router-view />
  <Footer />
  </div>
  
</template>

<script>
import Header from "./components/header.vue";
import Footer from "./components/footer.vue";
export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
};
</script>
<style>
@import "./assets/css/style.css";
@import "./assets/css/components.css";
@import "./assets/css/media.css";
</style>
