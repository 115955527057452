const configMapa = {
  clickableIcons: false,
  streetViewControl: false,
  panControlOptions: false,
  gestureHandling: "cooperative",
  mapTypeControl: false,
  zoom: 12,
  minZoom: 3,
  maxZoom: 12
};
 
export { configMapa};